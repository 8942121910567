import React, { ReactElement, useContext, useEffect } from "react";
import { connect, useFormikContext } from "formik";
import {
  DataGrid,
  NotificatiesContext,
  useRequestInit,
  hasJSONResponse,
} from "adviesbox-shared";
import { DocumentenSchemaType } from "../infra/documenten-schema";
import { documentKolommen } from "../documenten-kolommen";

const AanTeLeverenDocumenten = (): ReactElement => {
  const { setFieldValue, values } = useFormikContext<DocumentenSchemaType>();

  const notificatieContext = useContext(NotificatiesContext);
  const { params, user, settings } = useRequestInit();

  useEffect(() => {
    // Alleen doorgaan als er gevraagde documenten zijn met status "Aangeboden"
    const aangeboden = values.gevraagdeDocumenten?.filter(
      (x) => x.status === "Aangeboden"
    );
    if (aangeboden?.length === 0) return;

    // Bij een notificatie van het type "hdn-verzend-bericht-result" eventueel de status van het document aanpassen naar "Verzonden"
    const notificaties = [
      ...notificatieContext.notificaties.filter((x) =>
        x.message?.includes("hdn-verzend-bericht-result")
      ),
    ];
    // Loop door de notificaties van het type "hdn-verzend-bericht-result" heen
    notificaties.forEach((notificatie) => {
      const parser = JSON.parse(notificatie?.message ?? "");

      const berichtId = parser?.repliesTo?.berichtId;
      if (berichtId === undefined) return;

      // Haal het bericht op om het juiste document te kunnen identificeren
      const url = `${settings.hdnDossierOrigin}/bericht?berichtId=${berichtId}`;
      fetch(url, {
        method: "GET",
        headers: {
          authorization: `${user?.token_type} ${user?.access_token}`,
          "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
          vestigingId: params.vestiging ?? "",
        },
      }).then(async (response) => {
        if (response.ok && hasJSONResponse(response)) {
          // Haal het mijNr op om het juiste document te kunnen identificeren
          const json = await response.json();
          const parser = new DOMParser();
          const xml = parser.parseFromString(json?.xml, "text/xml");
          const mijNr = xml
            ?.querySelector("DocumentKenmerk")
            ?.querySelector("DocumentMijNr")?.textContent;
          /* istanbul ignore next */
          if (!mijNr) return;
          /* istanbul ignore next */
          const index = values.gevraagdeDocumenten.findIndex(
            (x) => x.mijnNr === mijNr && x.status !== "Verzonden"
          );
          if (index < 0) return;
          // Pas de status van het document aan naar "Verzonden"
          /* istanbul ignore next */
          setFieldValue(`gevraagdeDocumenten[${index}].status`, "Verzonden");
        }
      });
    });
  }, [
    notificatieContext.notificaties,
    params.vestiging,
    setFieldValue,
    settings.OcpApimSubscriptionKey,
    settings.hdnDossierOrigin,
    user,
    values.gevraagdeDocumenten,
  ]);

  /* istanbul ignore next */
  const onDataGridChange = (data?: DocumentenSchemaType[]): void => {
    if (!data || !data.length) return;
    setFieldValue("gevraagdeDocumenten", data);
  };

  return (
    <div className="m-2 pb-2">
      <DataGrid
        name={"gevraagdeDocumenten"}
        showButtonAddRow={false}
        columns={documentKolommen("aanTeLeveren")}
        resizable={false}
        sortable={true}
        sortedCallback={onDataGridChange}
        filterable={false}
      />
      <div className="pl-2">
        {values.gevraagdeDocumenten.length === 0 && (
          <p>Er zijn (momenteel) geen aan te leveren documenten</p>
        )}
      </div>
    </div>
  );
};

export default connect(AanTeLeverenDocumenten);
